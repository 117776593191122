import React, { useContext, useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Trans, propTrans } from '@lib/i18n';
import { IcoPlus, Lens } from '@icons';
import {
  getOrganizations,
  getTypes,
  // getClassesDistinct,
  getSam,
  postCreateOrganization,
  getAuthUser,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererOrganization from '@components/adminManagement/organizationsManagement/ActionBtnRendererOrganization';
import StatusBar from 'components/wizard/StatusBar';
import ImageBase64Converter from 'components/adminManagement/imageBase64Converter';
import { LogoContext } from 'components/LogoContext';

const initialState = {
  organization: '',
  nomeResponsabile: '',
  cognomeResponsabile: '',
  emailResponsabile: '',
  type: '',
  immagine: '',
  indirizzo: '',
  sam: [],
};

const transformValues = (object, property) => {
  return Object.values(object[property]).length > 0
    ? object[property].map((data) => data.value)
    : [];
};

const OrganizationsManagement = () => {
  const { setLogo } = useContext(LogoContext);
  const [organizations, setOrganizations] = useState([]);
  const [newOrganization, setNewOrganization] = useState(initialState);
  const [types, setTypes] = useState([]);
  const [optionsType, setOptionsType] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  // const [optionsClasses, setOptionsClasses] = useState([]);
  // const [selectedClass, setSelectedClass] = useState([]);
  const [optionsSam, setOptionsSam] = useState([]);
  const [selectedSam, setSelectedSam] = useState([]);
  // const [optionsDatasets, setOptionsDatasets] = useState([]);
  // const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);

  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  const animatedComponents = makeAnimated();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  console.log('organizations', organizations);
  console.log('newOrganization', newOrganization);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetForm = () => {
    setNewOrganization(initialState);
    setSbShow(<></>);
    setSelectedSam([]);
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const [typesResult, samResults] = await Promise.all([getTypes(), getSam()]);
      if (!abortController.signal.aborted) {
        setTypes(typesResult);
        setOptionsType(
          typesResult.map((type) => ({
            value: type.id,
            label: type.name,
          }))
        );
        // setOptionsClasses(
        //   classesDistinctResult.data
        //     .map((retrClass) => ({
        //       value: retrClass.name,
        //       label: retrClass.name,
        //     }))
        //     .sort((a, b) => a.label.localeCompare(b.label))
        // );
        setOptionsSam(
          samResults
            .map((sam) => ({
              value: sam.id,
              label: sam.title,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // setOptionsDatasets(
        //   datasetsResults.data
        //     .map((dataset) => ({
        //       value: dataset.id,
        //       label: dataset.name,
        //     }))
        //     .sort((a, b) => a.label.localeCompare(b.label))
        // );
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // getTypes().then((retrievedTypes) => {
    //   setTypes(retrievedTypes);

    //   setOptionsType(
    //     retrievedTypes.map((type) => ({
    //       value: type.id,
    //       label: type.name,
    //     }))
    //   );
    // });

    // getClassesDistinct().then((retrievedClasses) => {
    //   setOptionsClasses(
    //     retrievedClasses.data
    //       .map((retrClass) => ({
    //         value: retrClass.name,
    //         label: retrClass.name,
    //       }))
    //       .sort((a, b) => a.label.localeCompare(b.label))
    //   );
    // });

    // getSam().then((retrievedSam) => {
    //   setOptionsSam(
    //     retrievedSam
    //       .map((sam) => ({
    //         value: sam.id,
    //         label: sam.title,
    //       }))
    //       .sort((a, b) => a.label.localeCompare(b.label))
    //   );
    // // });
    // getOEDatasets().then((retrievedDatasets) => {
    //   setOptionsDatasets(
    //     retrievedDatasets.data
    //       .map((dataset) => ({
    //         value: dataset.id,
    //         label: dataset.name,
    //       }))
    //       .sort((a, b) => a.label.localeCompare(b.label))
    //   );
    // });

    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getOrganizations().then((retrievedOrganizations) => {
        setOrganizations(retrievedOrganizations);
        getAuthUser().then(({ user }) =>
          setLogo(
            retrievedOrganizations
              .filter((org) => org.idOrganization === user.idOrganization)
              .map((filteredOrg) => {
                return filteredOrg.immagine;
              })
              .join()
          )
        );
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onChange = (e, fieldname, setSelect) => {
    //console.log('onChange e', e);
    if (fieldname === 'immagine') {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Image = reader.result;

        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: base64Image,
        }));
      };

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      if (setSelect) {
        setSelect(e ?? []);

        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: e ? e.value ?? e : [],
        }));
      } else {
        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [e.target.name]: e.target.value,
        }));
      }
    }
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
    //gridRef.current.api.sizeColumnsToFit(); ??
  }, []);

  const onGridReady = useCallback(() => {
    getOrganizations().then((retrievedOrganizations) => {
      setOrganizations(retrievedOrganizations);
    });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    //gridRef.current.api.sizeColumnsToFit();
    //gridRef.current.columnApi.autoSizeAllColumns(false);
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);

    /* if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      } */
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onVirtualRowRemoved = useCallback((e) => {
    if (window.screen.width >= 2236) {
      //&& window.screen.height >= 768
      // Resolution is 2236 //x768 or above
      gridRef.current.api.sizeColumnsToFit();
    } else {
      const allColumnIds = [];
      gridRef.current.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  const columnDefs = [
    {
      headerName: 'ID',
      field: 'idOrganization',
      filter: 'agTextColumnFilter',
      maxWidth: 100,
      valueGetter: (params) => {
        return params.data.idOrganization;
      },
    },
    {
      headerValueGetter: () => propTrans({ prop: 'A0058' }),
      field: 'organization',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.organization;
      },
    },
    {
      headerValueGetter: () => propTrans({ prop: 'A0738' }),
      field: 'cognomeResponsabile',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const responsabile = `${params.data.nomeResponsabile} ${params.data.cognomeResponsabile}`;

        return responsabile;
      },
    },
    {
      headerName: 'Email',
      field: 'emailResponsabile',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.emailResponsabile;
      },
    },
    {
      headerValueGetter: () => propTrans({ prop: 'A0639' }),
      field: 'type',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const typeName = types
          .filter((type) => type.id === params.data.type)
          .map((filteredType) => filteredType.name);

        return typeName;
      },
    },
    {
      pinned: 'right',
      maxWidth: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ActionBtnRendererOrganization,
      cellRendererParams: {
        setRefresh: setRefresh,
        optionsType: optionsType,
        // optionsClasses: optionsClasses,
        optionsSam: optionsSam,
        // optionsDatasets: optionsDatasets,
      },
    },
  ];

  const handleNewOrganization = async (e) => {
    e?.preventDefault();

    setEmailIsInvalid(false);
    setSbShow(<></>);

    const {
      indirizzo,
      nomeResponsabile,
      cognomeResponsabile,
      emailResponsabile,
      sam,
      // classes,
      // datasets,
      ...restOrganization
    } = newOrganization;

    const isOrganizationFilled = Object.values(restOrganization).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isOrganizationFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }

    if (emailResponsabile && !emailResponsabile.match(emailRegex)) {
      setEmailIsInvalid(true);
      handleStatusBar('error', <Trans code='A0739' />, false);
      return;
    }

    newOrganization.sam = selectedSam;
    // newOrganization.classes = selectedClass;
    // newOrganization.datasets = selectedDatasets;

    newOrganization.sam = transformValues(newOrganization, 'sam');
    // newOrganization.classes = transformClassValues(newOrganization, 'classes');
    // newOrganization.datasets = transformValues(newOrganization, 'datasets');
    //  console.log('createResult', newOrganization);

    const createResult = await postCreateOrganization(newOrganization); //temp finche non accetta classes

    if (createResult === true) {
      handleStatusBar('success', <Trans code='A0657' />, true);

      setTimeout(() => {
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    } else if (createResult.message || createResult.error) {
      handleStatusBar('error', <Trans code='A0521' />, false);
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) !== 1) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
            {/* USER NOT AUTHORIZED */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='organizations' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-5'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0741' />
            </h5>
          </div>
          <div className='col-7 text-right'>
            <ButtonToolbar className='justify-content-end'>
              <Button
                variant='info add-organization'
                onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0742' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={organizations}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                //onFirstDataRendered={onFirstDataRendered}
                onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0740' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size='lg'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>
            <Trans code='A0743' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewOrganization} id='form-admin-organization'>
            <Row className='mb-3'>
              <Form.Group as={Col} className='col-6 px-4' controlId='immagine'>
                <Form.Label className='mb-3'>
                  Logo&nbsp;<span className='required-field'>*</span>&nbsp;
                </Form.Label>
                <Form.Control
                  type='file'
                  name='immagine'
                  // defaultValue={newOrganization.immagine}
                  onChange={(e) => onChange(e, 'immagine')}
                />
              </Form.Group>
              {newOrganization?.immagine.length > 0 && (
                <ImageBase64Converter
                  base64Image={newOrganization.immagine}
                  className={'organization-logo'}
                />
              )}
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='organization'>
                <Form.Label className='mb-3'>
                  <Trans code='A0744' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='organization'
                  defaultValue={newOrganization.organization}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='indirizzo'>
                <Form.Label className='mb-3'>
                  <Trans code='A0745' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='indirizzo'
                  defaultValue={newOrganization.indirizzo}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='nomeResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0746' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='nomeResponsabile'
                  defaultValue={newOrganization.nomeResponsabile}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='cognomeResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0747' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='cognomeResponsabile'
                  defaultValue={newOrganization.cognomeResponsabile}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='emailResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0748' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='emailResponsabile'
                  defaultValue={newOrganization.emailResponsabile}
                  onChange={(e) => onChange(e)}
                  isInvalid={emailIsInvalid}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='type'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0639' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='type'
                  value={selectedType}
                  options={optionsType}
                  onChange={(e) => onChange(e, 'type', setSelectedType)}
                />
              </Form.Group>
            </Row>
            {/* 
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0376' />
                </Form.Label>
                <Select
                  id='multiselect-class'
                  value={selectedClass}
                  closeMenuOnSelect={false}
                  options={optionsClasses}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'classes', setSelectedClass)}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0483' />
                </Form.Label>
                <Select
                  id='multiselect-class'
                  value={selectedDatasets}
                  closeMenuOnSelect={false}
                  options={optionsDatasets}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'datasets', setSelectedDatasets)}
                />
              </Form.Group>
            </Row> */}

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='sam'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='SAM' />
                </Form.Label>
                <Select
                  id='multiselect-sam'
                  value={selectedSam}
                  closeMenuOnSelect={false}
                  options={optionsSam}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'sam', setSelectedSam)}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={() => {
              resetForm();
              handleCloseModal(setShowModalNew);
            }}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewOrganization(e)}>
            <Trans code='A0644' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default OrganizationsManagement;
