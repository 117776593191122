import i18n from '@lib/i18n';
import Currency from '@lib/currency';
//const baseUrl = window.externalyticsApiUrl || 'https://externalytics-api.stage.openeconomics.ovh'

const baseUrl = process.env.REACT_APP_BASE_URL;
// const apiUrl = baseUrl + '/v2';

const credentials = {
  username: localStorage.getItem('external_user') || '',
  password: localStorage.getItem('external_password') || '',
};

const getAuth = () => {
  return btoa(`${credentials.username}:${credentials.password}`);
};

export const getDefaultHeaders = () => {
  const auth = getAuth();
  return {
    Authorization: `Basic ${auth}`,
    'Content-Type': 'application/json;',
  };
};

export const getUploadHeaders = () => {
  const auth = getAuth();
  return {
    Authorization: `Basic ${auth}`,
  };
};

export const getBaseUrl = (onlyUrl = false) => {
  return onlyUrl ? baseUrl : `${baseUrl}/${Currency.currency}/${i18n.language}`;
};

export const checkLogin = ({ username, password }) =>
  fetch(`${baseUrl}/login`, {
    headers: { Authorization: 'Basic ' + btoa(`${username}:${password}`) },
  })
    .then((r) => (r.status < 300 && r.status >= 200 ? 200 : r.status))
    .then((r) => {
      if (r === 200) {
        credentials.username = username;
        credentials.password = password;
        localStorage.setItem('external_user', username);
        localStorage.setItem('external_password', password);
      }
      return r;
    });

export const checkAuth = () =>
  fetch(`${baseUrl}/login`, { headers: { ...getDefaultHeaders() } }).then((r) =>
    r.status < 300 && r.status >= 200 ? 200 : r.status
  );

export const logout = () => {
  localStorage.removeItem('external_user');
  localStorage.removeItem('external_password');
  credentials.username = '';
  credentials.password = '';
};

export const getOpenScenari = () => {
  // return fetch(`${getBaseUrl()}/scenari`, {
  return fetch(`${baseUrl}/scenari?access=1&publish=1`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//OLD
export const getScenari = () => {
  // return fetch(`${getBaseUrl()}/scenari`, {
  return fetch(`${baseUrl}/scenari`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getScenarioMeta = (id) =>
  id
    ? getScenari().then((r) => r.filter((s) => parseInt(s.idScenario) === parseInt(id)).pop())
    : null;

export const getScenario = (idScenario) => {
  return fetch(`${getBaseUrl()}/scenari/${idScenario}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getCurrencies = () => {
  return fetch(`${baseUrl}/currencies`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getCountry = () => {
  // return fetch(`${getBaseUrl()}/country`, {
  return fetch(`${baseUrl}/getcountryfm`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//export const getCountryfmMeta = id => id ? getCountryfm().then(r => r.filter(s => parseInt(s.idCountry) === parseInt(id)).pop()) : null

export const getCountryfm = (idCountry) => {
  return fetch(`${getBaseUrl()}/countryfm/Italy`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//OLD
export const getAdminClasses = async (value) => {
  return fetch(`${baseUrl}/adminGetClasses/${value}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};
export const getAdminRegion = (value) => {
  return fetch(`${baseUrl}/adminGetRegion/${value}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getAdminSam = (country, region = null) => {
  if (region == null) {
    return fetch(`${baseUrl}/adminGetSam/${country}`, {
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());
  } else {
    return fetch(`${baseUrl}/adminGetSam/${country}/${region}`, {
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());
  }
};
export const getScenarioSelect = () => {
  return fetch(`${baseUrl}/adminGetScenarioSelect`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getTranslations = () => {
  return fetch(`${baseUrl}/language`).then((r) => r.json());
};

export const getTranslationsUrl = () => `${baseUrl}/language`;

export const getAuthUser = async () => {
  return fetch(`${baseUrl}/user`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getScenariDashboard = async () => {
  return fetch(`${baseUrl}/scenaridashboard`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getScenariKpi = async (idScenario) => {
  return fetch(`${baseUrl}/scenarikpi/${idScenario}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postScenariKpi = (data, idScenario) => {
  return fetch(`${baseUrl}/scenarikpi/${idScenario}`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

//ADMIN USERS
export const getUsers = () => {
  return fetch(`${baseUrl}/users`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postCreateUser = (data) => {
  return fetch(`${baseUrl}/users`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putEditUser = (data, userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const deleteUser = (userId) => {
  return fetch(`${baseUrl}/users/${userId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//ADMIN ROLES
export const getRoles = () => {
  return fetch(`${baseUrl}/roles`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//ADMIN TYPES
export const getTypes = () => {
  return fetch(`${baseUrl}/organizations/type`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//ADMIN SAM
export const getSam = () => {
  return fetch(`${baseUrl}/sam`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//ADMIN ORGANIZATIONS
//OLD
export const getAdminOrganization = (value) => {
  return fetch(`${baseUrl}/adminGetOrganization/${value}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getOrganizations = () => {
  return fetch(`${baseUrl}/organizations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getOrganization = (organizationId) => {
  return fetch(`${baseUrl}/organizations/${organizationId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postCreateOrganization = (data) => {
  return fetch(`${baseUrl}/organizations`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putEditOrganization = (data, organizationId) => {
  return fetch(`${baseUrl}/organizations/${organizationId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const deleteOrganization = (organizationId) => {
  return fetch(`${baseUrl}/organizations/${organizationId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//TAGS
export const getTags = () => {
  return fetch(`${baseUrl}/tags`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//OLD KPIs
export const getKpis = () => {
  return fetch(`${baseUrl}/kpi`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getKpi = (kpiId) => {
  return fetch(`${baseUrl}/kpi/${kpiId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postCreateKpi = (data) => {
  return fetch(`${baseUrl}/kpi`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putEditKpi = (data, kpiId) => {
  return fetch(`${baseUrl}/kpi/${kpiId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const deleteKpi = (kpiId) => {
  return fetch(`${baseUrl}/kpi/${kpiId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const copyKpi = (data, kpiId) => {
  return fetch(`${baseUrl}/kpi/clone/${kpiId}`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

//TEXT TRANSLATIONS
export const getTextTranslations = () => {
  return fetch(`${baseUrl}/translations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getTextTranslation = (translationCode) => {
  return fetch(`${baseUrl}/translations/${translationCode}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postCreateTranslation = (data) => {
  return fetch(`${baseUrl}/translations`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putEditTranslation = (data, translationCode) => {
  return fetch(`${baseUrl}/translations/${translationCode}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const deleteTranslation = (translationCode) => {
  return fetch(`${baseUrl}/translations/${translationCode}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//COUNTRIES
export const getCountries = () => {
  return fetch(`${baseUrl}/country`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

// export const deleteKpi = (kpiId) => {
//   return fetch(`${baseUrl}/kpi/${kpiId}`, {
//     method: 'DELETE',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//   }).then((r) => r.json());
// };

// export const putExpenseItem = (data) => {
//   return fetch(`${apiUrl}/expenseitems/${data.id}`, {
//     method: 'PUT',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//     body: JSON.stringify(data),
//   }).then((r) => r.json());
// };
// export const postExpenseItem = (data) => {
//   return fetch(`${apiUrl}/expenseitems`, {
//     method: 'POST',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//     body: JSON.stringify(data),
//   }).then((r) => r.json());
// };

//SCENARIOS DATASET SELECTION

//OLD DATASETS
export const getDataset = (dataSetId) => {
  return fetch(`${baseUrl}/datasets/${dataSetId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getDatasets = () => {
  return fetch(`${baseUrl}/v1/dataset`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postDataset = (dataset) => {
  return fetch(`${baseUrl}/v1/dataset`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(dataset),
  }).then((r) => r.json());
};

export const putDataset = (dataset) => {
  return fetch(`${baseUrl}/v1/dataset/${dataset.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(dataset),
  }).then((r) => r.json());
};

export const deleteDataset = (dataSetId) => {
  return fetch(`${baseUrl}/v1/dataset/${dataSetId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//BRIDGE MATRIX
export const getBridgeMatrix = () => {
  return fetch(`${baseUrl}/bridgematrix`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postBridgeMatrix = (listId) => {
  return fetch(`${baseUrl}/bridgematrix`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(listId),
  }).then((r) => r.json());
};

//EXPENSE ITEMS LIST
export const getExpenseItemsList = () => {
  return fetch(`${baseUrl}/expenseitemslist`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postExpenseItemsList = (data) => {
  return fetch(`${baseUrl}/expenseitemslist`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putExpenseItemsList = (data) => {
  return fetch(`${baseUrl}/expenseitemslist/${data.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const copyExpenseItemsList = (data, dataId) => {
  return fetch(`${baseUrl}/expenseitemslist/clone/${dataId}`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const deleteExpenseItemsList = (dataId) => {
  return fetch(`${baseUrl}/expenseitemslist/${dataId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

// export const putRunScenario = (scenarioId) => {
//   return fetch(`${apiUrl}/scenarios/${scenarioId}/analyses`, {
//     method: 'PUT',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//     body: JSON.stringify({ status: 'EXECUTED' }),
//   }).then((r) => r.json());
// };

export const downloadAsBlob = (url) => {
  return fetch(`${url}`, {
    method: 'GET',
  })
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
  //return res
};

const api = {
  getBaseUrl,
  getScenari,
  getOpenScenari,
  getScenarioMeta,
  getScenario,
  getCurrencies,
  getCountry,
  getCountryfm,
  getAdminRegion,
  getAdminSam,
  getAdminClasses,
  getScenarioSelect,
  getTranslations,
  getDefaultHeaders,
  getAuthUser,
  getScenariDashboard,
  getScenariKpi,
  postScenariKpi,

  getUsers,
  getUser,
  postCreateUser,
  putEditUser,
  deleteUser,
  getRoles,
  getTypes,
  getSam,
  getAdminOrganization,
  getOrganizations,
  getOrganization,
  postCreateOrganization,
  putEditOrganization,
  deleteOrganization,
  getTags,
  getKpis,
  getKpi,
  postCreateKpi,
  putEditKpi,
  deleteKpi,
  copyKpi,
  getTextTranslations,
  getTextTranslation,
  postCreateTranslation,
  putEditTranslation,
  deleteTranslation,
  getCountries,
  getDataset,
  getDatasets,
  postDataset,
  putDataset,
  deleteDataset,
  getBridgeMatrix,
  postBridgeMatrix,
  getExpenseItemsList,
  postExpenseItemsList,
  putExpenseItemsList,
  copyExpenseItemsList,
  deleteExpenseItemsList,
  downloadAsBlob,
};

export default api;
