import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col } from 'react-bootstrap';
import { IcoCanc, IcoEdit } from '@icons';
import { putEditTranslation, deleteTranslation } from '@lib/api';
import StatusBar from "components/wizard/StatusBar";

const ActionBtnRenderer = ({ data, setRefresh }) => {
   const originalCode = data.code;
   const [translation, setTranslation] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   console.log('translation:', translation);   

   useEffect(() => {
      setTranslation(data);
   }, [data]);

   const handleCloseModal = (setShowModal) => setShowModal(false);
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout}/>);
   };

   const onChange = (e, fieldname) => { 
      //console.log('onChange e', e);

      if (fieldname) {
         setTranslation(() => ({
            ...translation,
            [fieldname]: {
               ...translation[fieldname],
               [e.target.name]: e.target.value,
            },
         }));
      } else {
         setTranslation(() => ({
            ...translation,
            [e.target.name]: e.target.name === 'code' ? e.target.value.toUpperCase().replaceAll(" ", "") : e.target.value,
         }));
      }
   };

   const handleEditStatus = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const isCodeFilled = translation.code ? true : false;

      const areTextsFilled = [translation.IT.text, translation.EN.text, translation.ES.text]
         .every(prop => prop !== '' && prop !== null) ? true : false;

      if (!isCodeFilled || !areTextsFilled) {
         handleStatusBar('error', 'Compila i campi obbligatori.*', false);
         return;
      }

      const editResult = await putEditTranslation(translation, originalCode);
      console.log('editResult', editResult);

      if (editResult === true) {
         handleStatusBar('success', 'Informazioni modificate con successo*', true);

         setTimeout(() => {
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000);         
      } else if (editResult.duplicate) {
         handleStatusBar('error', 'Il codice inserito è già presente.*', false);
      } else if (editResult.message || editResult.error) {
         handleStatusBar('error', 'There was an error when saving information.*', false);
      }
   };

   const handleDelete = async () => {
      const deleteResult = await deleteTranslation(originalCode);
      //console.log('deleteResult', deleteResult);

      if (deleteResult === true) {
         handleStatusBar('success', 'Traduzione eliminata correttamente*', true);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000); 
      } else {
         handleStatusBar('error', 'Errore nell\'eliminazione della traduzione.*', false);
      }
   };

   const editTooltip = (props) => <Tooltip {...props}>Modifica</Tooltip>;
   const deleteTooltip = (props) => <Tooltip {...props}>Elimina</Tooltip>; 

   return (
      <div>
         <OverlayTrigger placement="top" overlay={editTooltip}>
            <Button variant="link" className="mr-3" onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className="second-color ico-small edit" />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <Button variant="link" className="mr-0" onClick={() => handleShowModal(setShowModalDelete)}>
               <IcoCanc className="second-color ico-small cancel" />
            </Button>
         </OverlayTrigger>

         <Modal size="lg" show={showModalEdit} onHide={() => handleCloseModal(setShowModalEdit)} centered>
            <Modal.Header closeButton>
               <Modal.Title className="px-2">Modifica traduzione*</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
               <Form onSubmit={handleEditStatus} id="form-admin-translation">
               <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="code">
                        <Form.Label className="mb-3">
                           Code&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="text" 
                           name="code"
                           maxLength="11"
                           value={translation.code}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Col>
                     </Col>
                  </Row>
                  
                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="ita-text">
                        <Form.Label className="mb-3">
                           Italian text:&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="text"
                           value={translation.IT?.text ?? ''}
                           onChange={e => onChange(e, 'IT')}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="ita-hover">
                        <Form.Label className="mb-3">
                           Italian hover:
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="hover"
                           value={translation.IT?.hover ?? ''}
                           onChange={e => onChange(e, 'IT')}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="eng-text">
                        <Form.Label className="mb-3">
                           English text:&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="text"
                           value={translation.EN?.text ?? ''}
                           onChange={e => onChange(e, 'EN')}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="eng-hover">
                        <Form.Label className="mb-3">
                           English hover:
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="hover"
                           value={translation.EN?.hover ?? ''}
                           onChange={e => onChange(e, 'EN')}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="esp-text">
                        <Form.Label className="mb-3">
                           Spanish text:&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="text"
                           value={translation.ES?.text ?? ''}
                           onChange={e => onChange(e, 'ES')}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="esp-hover">
                        <Form.Label className="mb-3">
                           Spanish hover:
                        </Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           name="hover"
                           value={translation.ES?.hover ?? ''}
                           onChange={e => onChange(e, 'ES')}
                        />
                     </Form.Group>
                  </Row>
               </Form>
               {sbShow}

               <Row>
                  <Col className='col-4 px-3'>
                     <span className='px-3'><span className="required-field">*</span>&nbsp;Campi obbligatori</span>
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer>  
               <Col className='px-3'>
                  <span>Sei sicuro di voler modificare le informazioni?*</span>
               </Col>
               <Col className='px-3 text-right'>
                  <Button variant="danger" onClick={() => handleCloseModal(setShowModalEdit)}>
                     Annulla
                  </Button>
                  <Button variant="primary" onClick={(e) => handleEditStatus(e)} className='ml-3'>
                     Conferma
                  </Button>
               </Col> 
            </Modal.Footer>
         </Modal>

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>Sei sicuro di voler eliminare la traduzione?*</span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={() => handleCloseModal(setShowModalDelete)}>
                  Annulla
               </Button>
               <Button variant="danger" onClick={() => handleDelete()}>
                  Elimina
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ActionBtnRenderer;
