import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Externalyticslogo, OpeneconomicsLogo } from '../loghi'
import { checkLogin } from '@lib/api'
import { useHistory } from "react-router-dom";
import ImpactFinance from '../pages/tab-impact-finance'
import Platform from '../pages/piattaforma'
import Method from '../pages/metodologia'

const LoginForm = ({ referrer }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const history = useHistory()

  const handleLogin = event => {
    event.preventDefault()
    checkLogin({username, password})
      .then(code => {
        if(code === 200) {
          if (referrer) history.push(referrer)
          else history.push('/')
        } else {
          setError(true)
        }
      })
  }

  return (
    <>
    <div className="header-site">
      <Container>
        <Row className="justify-content-between">
          <Col lg={5} className="text-login">
            <Externalyticslogo className="externalyticslogo" />
            <div><small>Powered by</small> <OpeneconomicsLogo className="openeconomicslogo" /></div>
            
          </Col>
          <Col lg={5} className="claim">
            <p className="infoLogin">A framework of practical solutions to manage externalities and risks related to investments and policies</p>
            <p className="claimLogin">THINK WIDER, LOOK CLOSER</p>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="login-container">
      <Container >
        <Row>
          <Col lg={3} className="text-login">
          </Col>
          <Col lg={5} className="simple-box form-login">
            <Form>
              <Form.Group controlId="formBasicText">
                <Form.Control type="text" placeholder="Username" value={username} onChange={({target: { value }}) => setUsername(value)} />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Control type="password" placeholder="Password" value={password} onChange={({target: { value }}) => setPassword(value)} />
              </Form.Group>
              <Button variant="info" type="submit" onClick={handleLogin}>
                Submit
              </Button>
              {error && <Form.Text className="text-muted">
                <h5>Login failed</h5>
              </Form.Text>}
              {/* <Form.Text className="text-muted">
                <a href="/">Forgot Password</a>
              </Form.Text> */}
            </Form>
            <hr />
            <div className="trial-text">
              {/* <a href="/">Sign up</a> now for a 30 days free trial */}
                <a href="mailto:info@openeconomics.eu?subject=Externalytics - Demo requested">Write us for a demo</a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <ImpactFinance/>
    <Platform/>
    <Method/>
    </>
  );
}

export default LoginForm;