import React, { useState, useEffect, Suspense } from 'react';
import './styles/main.scss';

import {
  BrowserRouter,
  Route,
  // Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import {
  Scenari,
  Scenario,
  Login,
  EconomicKPI,
  AnalisiImpatto,
  BibliografiaEssenziale,
  PubblicazioniOE,
  ImpactFinance,
  Platform,
  Method,
  SDG,
  WizardStep,
  Country,
  Administration,
  //Ico
} from '@pages';

import { Footprint, Keyindicators, Structure } from '@pages/countryprofile';
import {
  UsersManagement,
  OrganizationsManagement,
  TextsManagement,
} from '@pages/administrationpages';

import { LayoutBase, LayoutSimple, LayoutSideMenu } from './layouts';

import { checkAuth, logout, getTextTranslations, getAuthUser } from '@lib/api';

import client from '@lib/graphql';
import { ApolloProvider } from '@apollo/client';
import Opengallery from 'pages/opengallery';

//import { LayerStepCompact } from 'pages/index';

const SpinnerLoading = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}>
    <Spinner animation='border' role='status' />
  </div>
);

function App() {
  useEffect(() => {
    getTextTranslations().then((retrievedTranslations) => {
      localStorage.setItem('translations', JSON.stringify(retrievedTranslations));
    });
  }, []);
  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const externalyticsLanguage = localStorage.getItem('externalyticsLanguage');

    if (!externalyticsLanguage) {
      let languageToSet = 'en';

      if (['it', 'it-IT'].includes(userLanguage)) {
        languageToSet = 'it';
      } else if (['es', 'es-ES'].includes(userLanguage)) {
        languageToSet = 'es';
      }

      localStorage.setItem('externalyticsLanguage', languageToSet);
    }
  }, []);

  return (
    <Suspense fallback={<SpinnerLoading />}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' render={() => <Redirect to='/scenari' />} />
            {/* <AuthenticatedRoute path="/" exact component={() => <Home />} layout={LayoutBase} /> */}
            <AuthenticatedRoute
              path='/scenari'
              exact
              component={() => <Scenari />}
              layout={LayoutSideMenu}
              role={3}
              org
            />
            <UnauthenticatedRoute
              path='/login'
              exact
              component={() => <Login />}
              layout={LayoutSimple}
            />
            {/* <AuthenticatedRoute path="/dashboard" exact component={() => <Scenario />}  layout={LayoutBase} /> */}
            <AuthenticatedRoute
              path='/scenari/:idScenario/dashboard'
              component={(props) => <Scenario {...props} />}
              layout={LayoutSideMenu}
              role={3}
              org
            />

            <AuthenticatedRoute
              path='/scenari/comparators'
              component={() => <EconomicKPI />}
              layout={LayoutSideMenu}
              org
            />
            <AuthenticatedRoute
              path='/scenari/:idScenario/sdg'
              component={() => <SDG />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/about/analisi-impatto'
              component={() => <AnalisiImpatto />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/analisi-di-impatto'
              component={() => <ImpactFinance />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/piattaforma'
              component={() => <Platform />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/metodologia'
              component={() => <Method />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/bibliografia-essenziale'
              component={() => <BibliografiaEssenziale />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/pubblicazioni-OE'
              component={() => <PubblicazioniOE />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/country'
              component={() => <Country />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/opengallery'
              component={() => <Opengallery />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/footprint'
              component={() => <Footprint />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/structure'
              component={() => <Structure />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/key-indicators'
              component={() => <Keyindicators />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenariosetup/:idScenario'
              component={() => <WizardStep />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenariosetup'
              component={() => <WizardStep />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/administration'
              exact
              component={() => <Administration />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/administration/users'
              component={() => <UsersManagement />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/administration/organizations'
              component={() => <OrganizationsManagement />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/administration/texts/:translationCode?'
              component={(props) => <TextsManagement {...props} />}
              layout={LayoutSideMenu}
              role={1}
            />
            <LogoutRoute path='/logout' exact />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>
  );
}

// const AuthenticatedRoute = ({ component: Component, layout: Layout, role, ...rest }) => {
//   const [auth, setAuth] = useState(null);
//   const [userRole, setUserRole] = useState({ role: 1, organization: 1 });
//   checkAuth().then(setAuth);
//   console.log('auth', auth);
//   console.log('userRole', userRole);
//   console.log('rest', rest);
//   console.log('role', role);
//   useEffect(() => {
//     const abortController = new AbortController();

//     const fetchData = async () => {
//       const authResult = await getAuthUser();

//       if (abortController.signal.aborted === false) {
//         setUserRole({ role: +authResult.user.role, organization: +authResult.user.idOrganization });
//         localStorage.setItem('user', JSON.stringify(authResult.user));
//       }
//     };
//     fetchData();
//     return () => {
//       abortController.abort();
//     };
//   }, []);

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!auth) return null;
//         if (auth !== 200)
//           return (
//             <Redirect to={{ pathname: '/login', state: { referrer: rest.location.pathname } }} />
//           );

//         if (rest.path === '/scenari' || rest.path === '/scenari/comparators') {
//           if (userRole.organization === 14 || userRole.organization === 1) {
//             return (
//               <Layout {...props}>
//                 <Component {...props} />
//               </Layout>
//             );
//           } else if (userRole.organization !== 14 && userRole.organization !== 1) {
//             return (
//               <Redirect
//                 to={{
//                   pathname: '/scenari/newscenariosimpact',
//                 }}
//               />
//             );
//           }
//         } else if (rest.path === '/scenari/newscenariosimpact') {
//           if (role && userRole.role > role)
//             return (
//               <Redirect
//                 to={{
//                   pathname: '/scenari/newscenariosimpact',
//                   state: { referrer: rest.location.pathname },
//                 }}
//               />
//             );
//           if (userRole.organization === 14)
//             return (
//               <Redirect
//                 to={{
//                   pathname: '/scenari',
//                   state: { referrer: rest.location.pathname },
//                 }}
//               />
//             );
//         } else {
//         }

//         if (role && userRole.role > role)
//           return (
//             <Redirect
//               to={{
//                 pathname: '/scenari/newscenariosimpact',
//                 state: { referrer: rest.location.pathname },
//               }}
//             />
//           );
//         return (
//           <Layout {...props}>
//             <Component {...props} />
//           </Layout>
//         );
//       }}
//     />
//   );
// };

const AuthenticatedRoute = ({ component: Component, layout: Layout, role, ...rest }) => {
  const [auth, setAuth] = useState(null);
  const [userRole, setUserRole] = useState({ role: 1, organization: 1 });
  checkAuth().then(setAuth);
  // console.log('auth', auth);
  // console.log('userRole', userRole);
  // console.log('rest', rest);
  // console.log('role', role);
  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const authResult = await getAuthUser();

      if (abortController.signal.aborted === false && authResult.user) {
        setUserRole({
          role: +authResult.user.role,
          organization: +authResult.user.idOrganization,
        });
        localStorage.setItem('user', JSON.stringify(authResult.user));
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);
  console.log('userRole', userRole);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) return null;
        if (auth !== 200)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        if (role && userRole.role > role) {
          return (
            <Redirect
              to={{
                pathname: '/scenari',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        }

        // if (
        //   rest.path === '/scenari' &&
        //   ((parseInt(userRole.role) <= 3 &&
        //     (parseInt(userRole.organization) === 14 || parseInt(userRole.organization) === 1)) ||
        //     parseInt(userRole.role) === 1)
        // )
        //   return (
        //     <Layout {...props}>
        //       <Component {...props} />
        //     </Layout>
        //   );
        // if (rest.path === '/scenari/newscenariosimpact' && parseInt(userRole.organization) === 14)
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/scenari',
        //         state: { referrer: rest.location.pathname },
        //       }}
        //     />
        //   );
        // if (rest.org && userRole && userRole?.organization !== 1 && userRole?.organization !== 14)
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/scenari',
        //         state: { referrer: rest.location.pathname },
        //       }}
        //     />
        //   );
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const UnauthenticatedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const LogoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  logout();
  return <Redirect to='/login' />;
};

export default App;
