import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { FormControl, Button, Form, NavDropdown } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as Externalyticslogo } from '../loghi/externalytics_logo.svg';
import { ReactComponent as Externalyticsico } from '../loghi/externalytics_ico.svg';
import { ReactComponent as Poweredbylogo } from '../loghi/powered_by_oe.svg';
import { ReactComponent as User } from '@icons/user.svg';
import { ReactComponent as Lens } from '@icons/search_lens.svg';
import { getAuthUser } from '@lib/api';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Trans } from '@lib/i18n';
import Currency from '@lib/currency';

import UserLogo from '@components/UserLogo';

const langoptions = [
  { value: 'it', label: 'ITA' },
  { value: 'es', label: 'ESP' },
  { value: 'en', label: 'ENG' },
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    padding: 10,
  }),
  control: () => ({
    display: 'flex',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    padding: '2px 8px 2px 0px',
  }),
  input: () => ({
    margin: '0px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: '#666666' };
  },
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    width: '100%',
  }),
};

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const handleChange = ({ value }) => {
    i18n.changeLanguage(value);
    localStorage.setItem('externalyticsLanguage', value);
    window.location.reload();
  };

  return (
    <Select
      styles={customStyles}
      defaultValue={langoptions.find((l) => l.value === i18n.language)}
      options={langoptions}
      onChange={handleChange}
      classNamePrefix='custom-select'
    />
  );
};

class Langselect extends React.Component {
  render() {
    return (
      <Select
        styles={customStyles}
        defaultValue={langoptions[0]}
        options={langoptions}
        classNamePrefix='custom-select'
      />
    );
  }
}

class Currselect extends React.Component {
  render() {
    return (
      <Select
        styles={customStyles}
        defaultValue={Currency.getCurrencyObject()}
        options={Currency.currencies}
        classNamePrefix='custom-select'
        onChange={Currency.setCurrencyObject}
        isDisabled={true}
      />
    );
  }
}

const Header = () => {
  const location = useLocation();
  const [authUser, setAuthUser] = useState({});

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const authResult = await getAuthUser();
      if (abortController.signal.aborted === false) {
        setAuthUser(authResult.user);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  const pathSegments = location.pathname.split('/');
  const isActiveAnalisiEconomica =
    pathSegments.length > 1 &&
    (pathSegments[1] === 'scenario' ||
      pathSegments[1] === 'scenariowizard' ||
      pathSegments[1] === 'scenari' ||
      pathSegments[1] === 'scenariosetup');

  return (
    <header id='site-header'>
      <Navbar expand='lg' variant='light'>
        <Nav className='mr-auto left-side'>
          <Navbar.Brand href='/'>
            <Externalyticsico className='externalyticsico' />
            <Externalyticslogo className='externalyticslogo' />
          </Navbar.Brand>
          <div className='d-none d-lg-block'>
            <Poweredbylogo className='poweredbylogo' />
          </div>
        </Nav>
        <Nav className='ml-auto right-side'>
          {/* <Nav.Link href="#home" className="d-none d-lg-block">ABOUT</Nav.Link> */}
          <Form inline className='search-bar justify-content-end'>
            <FormControl type='text' placeholder='Search...' className='search-box' />
            <Button variant='link' className='search-btn'>
              <Lens />
            </Button>
          </Form>
          <NavDropdown id='about-nav-dropdown' title='ABOUT' className='about d-none d-lg-block'>
            <NavDropdown.Item href='/about/analisi-di-impatto'>
              <Trans code='A0045' />
            </NavDropdown.Item>
            <NavDropdown.Item href='/about/piattaforma'>Piattaforma</NavDropdown.Item>
            <NavDropdown.Item href='/about/metodologia'>
              <Trans code='A0006' />
            </NavDropdown.Item>
            <NavDropdown.Item href='/bibliografia-essenziale'>
              <Trans code='A0033' />
            </NavDropdown.Item>
            <NavDropdown.Item href='/pubblicazioni-OE'>
              <Trans code='A0032' />
            </NavDropdown.Item>
          </NavDropdown>
          <Form inline className='d-none d-lg-block select-lang'>
            <LanguageSelect />
          </Form>
          <Form inline className='d-none d-lg-block select-currency'>
            <Currselect />
          </Form>
          <NavDropdown
            id='user-nav-dropdown'
            title={
              <span>
                {authUser?.username}
                <User className='userico' />
              </span>
            }
            className='user d-none d-lg-block'>
            <NavDropdown.Item href='/logout'>Logout</NavDropdown.Item>
          </NavDropdown>
          <UserLogo className='enellogo desktop-logo' />
          <Dropdown className='d-block d-lg-none dropdown-table'>
            <Dropdown.Toggle id='tablet-toggle' variant='link'>
              <UserLogo className='enellogo' />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item href="#/action-1">ABOUT</Dropdown.Item> */}
              <Form inline className='select-lang'>
                <Langselect />
              </Form>
              <Form inline className='select-currency'>
                <Currselect />
              </Form>
              <NavDropdown id='about-nav-dropdown' title='ABOUT' className='about'>
                <NavDropdown.Item href='/about/analisi-di-impatto'>
                  <Trans code='A0045' />
                </NavDropdown.Item>
                <NavDropdown.Item href='/about/piattaforma'>Piattaforma</NavDropdown.Item>
                <NavDropdown.Item href='/about/metodologia'>
                  <Trans code='A0006' />
                </NavDropdown.Item>
                <NavDropdown.Item href='/bibliografia-essenziale'>
                  <Trans code='A0033' />
                </NavDropdown.Item>
                <NavDropdown.Item href='/pubblicazioni-OE'>
                  <Trans code='A0032' />
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                id='user-nav-dropdown'
                title={<User className='userico' />}
                className='user'>
                <NavDropdown.Item href='/logout'>Logout</NavDropdown.Item>
              </NavDropdown>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>
      <Navbar variant='light' className='sub-navbar'>
        <Nav activeKey={location.pathname.split('/').slice(0, 2).join('/')}>
          <Nav.Item>
            <Nav.Link href='/scenari' active={isActiveAnalisiEconomica}>
              <Trans code='A0045' />
            </Nav.Link>
          </Nav.Item>
          {/* {authUser && parseInt(authUser.role) === 1 && (
            <>
              <Nav.Item>
                <Nav.Link href='/countryprofile'>DATA HUB</Nav.Link>
              </Nav.Item>
            </>
          )} */}
          {authUser && parseInt(authUser.role) === 1 && (
            <>
              <Nav.Item>
                <Nav.Link href='/opengallery'>OPEN GALLERY</Nav.Link>
              </Nav.Item>
            </>
          )}
          {/* {authUser &&
            parseInt(authUser.role) <= 3 &&
            authUser &&
            parseInt(authUser.idOrganization) === 1 && (
              <>
                <Nav.Item>
                  <Nav.Link href='https://externalytics-esg.web.app/progetti' target='_blank'>
                    ESG
                  </Nav.Link>
                </Nav.Item>
              </>
            )} */}
          {/* {authUser && parseInt(authUser.role) <= 2 && (
            <>
              <Nav.Item>
                <Nav.Link href='/modelling'>MODELLING</Nav.Link>
              </Nav.Item>
            </>
          )} */}

          {authUser && parseInt(authUser.role) === 1 && (
            <>
              <Nav.Item>
                <Nav.Link href='/administration'>ADMINISTRATION</Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>
      </Navbar>
    </header>
  );
};

export default withRouter(Header);
