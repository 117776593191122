import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { IcoCanc, IcoEdit } from '@icons';
import { deleteUser, putEditUser } from '@lib/api';
import StatusBar from "components/wizard/StatusBar";

const ActionBtnRenderer = ({ data, setRefresh, optionsRole, optionsOrganization }) => {
   const id = data.idUser;
   const [user, setUser] = useState({});
   const [selectedRole, setSelectedRole] = useState([]);
   const [selectedOrganization, setSelectedOrganization] = useState([]);
   const [confirmPassword, setConfirmPassword] = useState('');
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [passwIsInvalid, setPasswIsInvalid] = useState(false);
   const [emailIsInvalid, setEmailIsInvalid] = useState(false);
   const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
   //console.log('selected user', user);

   useEffect(() => {
      setUser(data);

      setSelectedRole(
         optionsRole.filter(optionRole => optionRole.value === data.role)
         .map((filteredRole) => ({
            value: filteredRole.value,
            label: filteredRole.label,
         }))
      );

      setSelectedOrganization(
         optionsOrganization.filter(optionOrganization => optionOrganization.label === data.organization)
         .map((filteredOrganization) => ({
            value: filteredOrganization.value,
            label: filteredOrganization.label,
         }))
      );
   }, [data, optionsRole, optionsOrganization]);

   const handleCloseModal = (setShowModal) => setShowModal(false);
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout}/>);
   };

   const onChange = (e, fieldname, setSelect) => { 
      if (setSelect) {
         setSelect(e);

         setUser(() => ({
            ...user,
            [fieldname]: e.value,
         }));
      } else {
         setUser(() => ({
            ...user,
            [e.target.name]: e.target.value,
         }));
      }
   };

   const handleEditStatus = async (e) => {
      e?.preventDefault();

      setPasswIsInvalid(false);
      setEmailIsInvalid(false);
      setSbShow(<></>);

      const { 
         cognomeResponsabile,
         dataRegistrazione,
         emailResponsabile,
         immagine,
         indirizzo,
         lastLogin,
         nomeResponsabile,
         organization,
         publish,
         password,
         ...restUser
      } = user;
      
      const isEverythingVerified = Object.values(restUser).every(prop => prop !== '' && prop != null) ? true : false;

      if (!isEverythingVerified) {
         handleStatusBar('error', 'Compila i campi obbligatori.*', false);
         return;
      }

      if (user.email && !user.email.match(emailRegex)) {
         setEmailIsInvalid(true);
         handleStatusBar('error', 'Email non valida.*', false);
         return;
      }

      if (user.password && confirmPassword !== user.password) {
         setPasswIsInvalid(true);
         handleStatusBar('error', 'Le password non corrispondono*', false);
         return;
      }

      const editResult = await putEditUser(user, id);
      //console.log('editResult', editResult);

      if (editResult === true) {
         handleStatusBar('success', 'Informazioni modificate con successo*', true);

         setTimeout(() => {
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000);         
      } else if (editResult.duplicate) {
         handleStatusBar('error', 'L\'username inserito è già presente.*', false);
      } else if (editResult.message || editResult.error) {
         handleStatusBar('error', 'There was an error when saving information.*', false);
      }
   };

   const handleDelete = async () => {
      const deleteResult = await deleteUser(id);
      //console.log('deleteResult', deleteResult);

      if (deleteResult === true) {
         handleStatusBar('success', 'Utente eliminato correttamente*', true);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000); 
      } else {
         handleStatusBar('error', 'Errore nell\'eliminazione dell\'utente.*', false);
      }
   };

   const editTooltip = (props) => <Tooltip {...props}>Modifica</Tooltip>;
   const deleteTooltip = (props) => <Tooltip {...props}>Elimina</Tooltip>; 

   return (
      <div>
         <OverlayTrigger placement="top" overlay={editTooltip}>
            <Button variant="link" className="mr-3" onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className="second-color ico-small edit" />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <Button variant="link" className="mr-0" onClick={() => handleShowModal(setShowModalDelete)}>
               <IcoCanc className="second-color ico-small cancel" />
            </Button>
         </OverlayTrigger>

         <Modal size="lg" show={showModalEdit} onHide={() => handleCloseModal(setShowModalEdit)} centered>
            <Modal.Header closeButton>
               <Modal.Title className="px-2">Modifica utente*</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
               <Form onSubmit={handleEditStatus} id="form-admin-user">
                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="nome">
                        <Form.Label className="mb-3">
                           Nome&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="text" 
                           name="nome"
                           defaultValue={user.nome}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="cognome">
                        <Form.Label className="mb-3">
                           Cognome&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="text" 
                           name="cognome"
                           defaultValue={user.cognome}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>
                  
                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="email">
                        <Form.Label className="mb-3">
                           Email&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="email" 
                           name="email"
                           defaultValue={user.email}
                           onChange={e => onChange(e)}
                           isInvalid={emailIsInvalid}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="username">
                        <Form.Label className="mb-3">
                           Username&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="text" 
                           name="username"
                           defaultValue={user.username}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>
                                    
                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="role">
                        <Form.Label className="mb-3 mr-4">
                           Role&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           name="role"
                           value={selectedRole}
                           options={optionsRole}
                           onChange={e => onChange(e, 'role', setSelectedRole)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="idOrganization">
                        <Form.Label className="mb-3 mr-4">
                           Organization&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           name="idOrganization"
                           value={selectedOrganization}
                           options={optionsOrganization}
                           onChange={e => onChange(e, 'idOrganization', setSelectedOrganization)}
                        />
                     </Form.Group>
                  </Row>

                  <hr className="my-4"/>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="password">
                        <Form.Label className="mb-3">
                           Nuova password
                        </Form.Label>
                        <Form.Control
                           type="password"
                           name="password"
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>

                     <Form.Group as={Col} className="px-4" controlId="confirmPassword">
                        <Form.Label className="mb-3">
                           Conferma password
                        </Form.Label>
                        <Form.Control
                           type="password"
                           name="confirmPassword"
                           onChange={e => setConfirmPassword(e.target.value)}
                           isInvalid={passwIsInvalid}
                        />
                     </Form.Group>
                  </Row>
               </Form>
               {sbShow}

               <Row>
                  <Col className='col-4 px-3'>
                     <span className='px-3'><span className="required-field">*</span>&nbsp;Campi obbligatori</span>
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer>  
               <Col className='px-3'>
                  <span>Sei sicuro di voler modificare le informazioni?*</span>
               </Col>
               <Col className='px-3 text-right'>
                  <Button variant="danger" onClick={() => handleCloseModal(setShowModalEdit)}>
                     Annulla
                  </Button>
                  <Button variant="primary" onClick={(e) => handleEditStatus(e)} className='ml-3'>
                     Conferma
                  </Button>
               </Col> 
            </Modal.Footer>
         </Modal>

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>Sei sicuro di voler eliminare l'utente?*</span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={() => handleCloseModal(setShowModalDelete)}>
                  Annulla
               </Button>
               <Button variant="danger" onClick={() => handleDelete()}>
                  Elimina
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ActionBtnRenderer;
