import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { IcoPlus, Lens } from '@icons';
import { getUsers, getOrganizations, getRoles, postCreateUser } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererUser from '@components/adminManagement/usersManagement/ActionBtnRendererUser';
import StatusBar from 'components/wizard/StatusBar';

const initialState = {
  nome: '',
  cognome: '',
  email: '',
  username: '',
  password: '',
  role: '',
  idOrganization: '',
};

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(initialState);
  const [roles, setRoles] = useState([]);
  const [optionsRole, setOptionsRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [optionsOrganization, setOptionsOrganization] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwIsInvalid, setPasswIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  //console.log('users', users);
  //console.log('newUser', newUser);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const [rolesResult, organizationsResult] = await Promise.all([
        getRoles(),
        getOrganizations(),
      ]);
      if (!abortController.signal.aborted) {
        setRoles(rolesResult);
        setOrganizations(organizationsResult);
      }
    };
    // getAuthUser().then(({ user }) => {
    //   setAuthUser(user);
    // });
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    /* getUsers().then((retrievedUsers) => {
         setUsers(retrievedUsers);
      }); */

    // getRoles().then((retrievedRoles) => {
    //    setRoles(retrievedRoles);
    // });

    // getOrganizations().then((retrievedOrganizations) => {
    //    setOrganizations(retrievedOrganizations);
    // });

    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    setOptionsRole(
      roles.map((role) => ({
        value: role.id,
        label: role.role,
      }))
    );

    setOptionsOrganization(
      organizations.map((organization) => ({
        value: organization.idOrganization,
        label: organization.organization,
      }))
    );
  }, [roles, organizations]);

  useEffect(() => {
    if (refresh) {
      getUsers().then((retrievedUsers) => {
        setUsers(retrievedUsers);
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onChange = (e, fieldname, setSelect) => {
    if (setSelect) {
      setSelect(e);

      setNewUser(() => ({
        ...newUser,
        [fieldname]: e.value,
      }));
    } else {
      setNewUser(() => ({
        ...newUser,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onGridReady = useCallback(() => {
    getUsers().then((retrievedUsers) => {
      setUsers(retrievedUsers);
    });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    console.log('params2', params);
    //gridRef.current.api.sizeColumnsToFit();
    //gridRef.current.columnApi.autoSizeAllColumns(false);
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);

    /* if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      } */
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onVirtualRowRemoved = useCallback((e) => {
    if (window.screen.width >= 2236) {
      //&& window.screen.height >= 768
      // Resolution is 2236 //x768 or above
      gridRef.current.api.sizeColumnsToFit();
    } else {
      const allColumnIds = [];
      gridRef.current.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  const columnDefs = [
    {
      headerName: 'ID*',
      field: 'idUser',
      filter: 'agTextColumnFilter',
      maxWidth: 90,
      valueGetter: (params) => {
        return params.data.idUser;
      },
    },
    {
      headerName: 'Nome',
      field: 'nome',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.nome;
      },
    },
    {
      headerName: 'Cognome',
      field: 'cognome',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.cognome;
      },
    },
    {
      headerName: 'Username',
      field: 'username',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.username;
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.email;
      },
    },
    {
      headerName: 'Organization',
      field: 'organization',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.organization;
      },
    },
    {
      headerName: 'Role',
      field: 'role',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const roleName = roles
          .filter((role) => role.id === params.data.role)
          .map((filteredRole) => filteredRole.role);

        return roleName;
      },
    },
    {
      pinned: 'right',
      maxWidth: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ActionBtnRendererUser,
      cellRendererParams: {
        setRefresh: setRefresh,
        optionsRole: optionsRole,
        optionsOrganization: optionsOrganization,
      },
    },
  ];

  const handleNewUser = async (e) => {
    e?.preventDefault();

    setPasswIsInvalid(false);
    setEmailIsInvalid(false);
    setSbShow(<></>);

    const isEverythingVerified = Object.values(newUser).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    if (!isEverythingVerified) {
      handleStatusBar('error', 'Tutti i campi sono obbligatori.*', false);
      return;
    }

    if (newUser.email && !newUser.email.match(emailRegex)) {
      setEmailIsInvalid(true);
      handleStatusBar('error', 'Email non valida.*', false);
      return;
    }

    if (confirmPassword !== newUser.password) {
      setPasswIsInvalid(true);
      handleStatusBar('error', 'Le password non corrispondono*', false);
      return;
    }

    const createResult = await postCreateUser(newUser);
    //console.log('createResult', createResult);

    if (createResult === true) {
      handleStatusBar('success', 'Utente creato con successo*', true);

      setTimeout(() => {
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    } else if (createResult.duplicate) {
      handleStatusBar('error', "L'username inserito è già presente.*", false);
    } else if (createResult.message || createResult.error) {
      handleStatusBar('error', 'There was an error when saving information.*', false);
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) !== 1) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>USER NOT AUTHORIZED</div>
        </div>
      </div>
    );
  }

  return (
    <div id='users' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-6'>
            <h5 className='title-simple-box mb-0'>Users</h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>Nuovo utente</span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={users}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                //onFirstDataRendered={onFirstDataRendered}
                onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                Utenti per pagina:&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size='lg'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>Crea un nuovo utente*</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewUser} id='form-admin-user'>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='nome'>
                <Form.Label className='mb-3'>
                  Nome&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='nome'
                  defaultValue={newUser.nome}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='cognome'>
                <Form.Label className='mb-3'>
                  Cognome&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='cognome'
                  defaultValue={newUser.cognome}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='email'>
                <Form.Label className='mb-3'>
                  Email&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  defaultValue={newUser.email}
                  onChange={(e) => onChange(e)}
                  isInvalid={emailIsInvalid}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='username'>
                <Form.Label className='mb-3'>
                  Username&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='username'
                  defaultValue={newUser.username}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='role'>
                <Form.Label className='mb-3 mr-4'>
                  Role&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='role'
                  value={selectedRole}
                  options={optionsRole}
                  onChange={(e) => onChange(e, 'role', setSelectedRole)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='idOrganization'>
                <Form.Label className='mb-3 mr-4'>
                  Organization&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='idOrganization'
                  value={selectedOrganization}
                  options={optionsOrganization}
                  onChange={(e) => onChange(e, 'idOrganization', setSelectedOrganization)}
                />
              </Form.Group>
            </Row>

            <hr className='my-4' />

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='password'>
                <Form.Label className='mb-3'>
                  Nuova password&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control type='password' name='password' onChange={(e) => onChange(e)} />
              </Form.Group>

              <Form.Group as={Col} className='px-4' controlId='confirmPassword'>
                <Form.Label className='mb-3'>
                  Conferma password&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='password'
                  name='confirmPassword'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isInvalid={passwIsInvalid}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <Row>
            <Col className='col-4 px-3'>
              <span className='px-3'>
                <span className='required-field'>*</span>&nbsp;Campi obbligatori
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => handleCloseModal(setShowModalNew)}>
            Annulla
          </Button>
          <Button variant='primary' onClick={(e) => handleNewUser(e)}>
            Crea
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default UsersManagement;
