import React from "react"
import { LoginForm } from "@components"
import { useLocation } from 'react-router-dom'

const Login = props => {
  const location = useLocation()
  return (
    <div id="loginPage">
      <LoginForm referrer={location?.state?.referrer} />
    </div>
  );
}

export default Login;