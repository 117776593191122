import React, { useState, useEffect, Fragment } from 'react';
import { Table, Button, FormControl, ButtonToolbar, InputGroup, Pagination } from 'react-bootstrap';
import { Lens, IcoCanc, IcoEdit, IcoPlus /* IcoCopy */ } from '@icons';
import { getScenari, getBaseUrl, getDefaultHeaders, getAuthUser } from '@lib/api';
import { Link } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
import { date } from '@lib/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStop,
  faCopy,
  faThLarge,
  faFileAlt,
  faDollarSign,
  faSortUp,
  faSortDown,
  faSort,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
//import Tooltip from '@lib/tooltip/index';
//import { TooltipInfoIcon } from 'icone/index';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ScenariDashboard from '@components/scenari/scenariDashboard';

const ScenariTab = () => {
  const [scenari, setScenari] = useState([]);
  const [fScenari, setFScenari] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState({ key: 'scenario_name', direction: 'ASC' });
  const [user, setUser] = useState(null);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  //const current_username = localStorage.getItem('external_user')
  //console.log('scenari:', scenari);

  const numberPagination = [
    <Fragment key='options'>
      <option defaultValue={20} key='20'>
        20
      </option>
      <option value={10} key='10'>
        10
      </option>
      <option value={50} key='50'>
        50
      </option>
    </Fragment>,
  ];

  useEffect(() => {
    getAuthUser().then(({ user }) => {
      setUser(user);
      //console.log('user', user);

      localStorage.setItem('user', JSON.stringify(user));
      //console.log(('local user:', localStorage.getItem('user')))
    });
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const scenarioResults = await getScenari();

      if (abortController.signal.aborted === false) {
        sortScenari('scenario_name');
        //   filterAndPaginate(scenari);
        setFScenari(scenarioResults);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
    // getScenari()
    //   // Filter scenari, use only published ones
    //   //.then(scenari => scenari.filter(s => parseInt(s.publish) === 1))
    //   // Pagination and scenari set in state
    //   .then((scenari) => {
    //     sortScenari('scenario_name');
    //     //   filterAndPaginate(scenari);
    //     setFScenari(scenari);
    //   });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fScenari.length > 0) {
      filterAndPaginate(fScenari);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsPerPage, fScenari]);

  const getPaginationItems = (page, pages, max = 6) => {
    console.log('TABSCEN', page, pages, max);
    const items = [];

    if (pages <= max) {
      for (let i = 1; i <= pages; i++) {
        items.push(
          <Pagination.Item active={page === i} key={i} onClick={() => setPage(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      const half = Math.floor(max / 2);

      if (page > 1) {
        items.push(<Pagination.First key={0} onClick={() => setPage(1)} />);
      }

      let start;

      start = page - half;
      if (start < 1) start = 1;

      for (let p = start; p <= page; p++) {
        items.push(
          <Pagination.Item key={p} data-page={p} onClick={() => setPage(p)} active={page === p}>
            {p}
          </Pagination.Item>
        );
      }

      let counter;
      let last = page + half <= pages ? page + half : pages;

      for (let n = page + 1; n <= last; n++) {
        items.push(
          <Pagination.Item key={n} data-page={n} onClick={() => setPage(n)}>
            {n}
          </Pagination.Item>
        );
        counter = n;
      }

      if (counter < pages) {
        items.push(<Pagination.Last key={9999} onClick={() => setPage(pages)} />);
      }
    }
    console.log('ITEMS', scenari);
    return items;
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
    filterAndPaginate(fScenari, value);
  };

  const filterAndPaginate = (scenari, value = search) => {
    setPage(1);
    const filteredScenari = scenari.filter((s) => {
      if (value.length > 0) {
        return (
          (s.scenario_name || '').toLowerCase().search(value.toLowerCase()) > -1 ||
          (s.cod_nace_description || '').toLowerCase().search(value.toLowerCase()) > -1
        );
      }
      return true;
    });

    // TODO CHANGE THE DIVISION
    setPages(Math.ceil(filteredScenari.length / recordsPerPage));
    //console.log('filteredScenari', filteredScenari)
    setScenari(filteredScenari);
  };
  console.log('PAGES', pages);
  const deleteScenario = (idScenario) => {
    //event.preventDefault()
    // setError(false)
    // setIsSaved(false)
    if (window.confirm('Are you sure?')) {
      fetch(`${getBaseUrl()}/deleteScenario/${idScenario}`, {
        method: 'GET',
        headers: {
          ...getDefaultHeaders(),
        },
      })
        .then((data) => {
          window.location.reload();
        })
        .catch((error) => {
          // setError(true)
          console.error(error);
        });
    }
  };

  const duplicaScenario = (idScenario) => {
    fetch(`${getBaseUrl()}/duplicaScenario/${idScenario}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    })
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        // setError(true)
        console.error(error);
      });
  };

  const sortScenari = (attribute) => {
    if (ordering.key === attribute) {
      var ord = (ordering.direction = ordering.direction === 'ASC' ? 'DESC' : 'ASC');
      setOrdering({ key: attribute, direction: ord });
    } else {
      ordering.key = attribute;
      ordering.direction = 'ASC';
      setOrdering({ key: attribute, direction: 'ASC' });
    }

    const orderedScenari = scenari.sort((a, b) => {
      //console.log('a[attribute] test:', a[attribute], Date.parse(a.dataModifica), a.scenario_name)
      //console.log('b[attribute] test:', b[attribute], Date.parse(b.dataModifica), b.scenario_name)

      if (attribute === 'dataModifica') {
        let aFixed = a[attribute] ?? a.dataCreazione;
        let bFixed = b[attribute] ?? b.dataCreazione;

        return ordering.direction === 'ASC'
          ? Date.parse(bFixed) - Date.parse(aFixed)
          : Date.parse(aFixed) - Date.parse(bFixed);
      } else {
        if (a[attribute] < b[attribute]) return ordering.direction === 'ASC' ? -1 : 1;
        if (a[attribute] > b[attribute]) return ordering.direction === 'ASC' ? 1 : -1;
        return 0;
      }
    });
    //const orderedScenari = scenari.sort((a, b) => ordering.direction === 'ASC' ? a[attribute].localeCompare(b[attribute]) : b[attribute].localeCompare(a[attribute]))
    setScenari(orderedScenari);
    //console.log('ordering', ordering)
    filterAndPaginate(orderedScenari);
  };

  return (
    <>
      <ScenariDashboard />
      <div className='simple-box scenaritab'>
        <div className='row d-flex align-items-center'>
          <div className='col-4'>
            <h5 className='title-simple-box'>
              <Trans code='F0059' />
            </h5>
          </div>
          <div className='col-8 text-right'>
            <ButtonToolbar className='justify-content-end align-items-center'>
              <Button variant='info' href='scenariosetup'>
                <div className='row mx-0 align-items-center' style={{ padding: '2px 0' }}>
                  <IcoPlus className='light-color ico-small plus mr-1' />
                  <span className='d-none d-md-inline'>
                    &nbsp;
                    <Trans code='A0037' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table'>
                <Button variant='link' className='search-btn'>
                  <Lens />
                </Button>
                <FormControl
                  type='text'
                  placeholder='Search'
                  className='d-none d-md-block'
                  value={search}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Table className='table' responsive>
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => sortScenari('scenario_name')} className='w-25'>
                    <Trans code='A0348' />{' '}
                    {ordering.key === 'scenario_name' ? (
                      ordering.direction === 'ASC' ? (
                        <FontAwesomeIcon icon={faSortUp} className='bluicon ml-2' />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className='bluicon ml-2' />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className='bluicon ml-2' />
                    )}
                  </th>
                  <th className='d-none d-lg-table-cell text-center'>
                    <Trans code='A0201' />
                  </th>
                  <th className='d-none d-lg-table-cell text-center'>Status</th>
                  {/* <th  onClick={() => sortScenari('cod_nace_description')} className="d-none d-md-table-cell"><Trans code="A0068"/>{ordering.key==='cod_nace_description'?(ordering.direction==='ASC'?<FontAwesomeIcon icon={faSortUp} className="bluicon ml-2" />:<FontAwesomeIcon icon={faSortDown} className="bluicon ml-2" />):<FontAwesomeIcon icon={faSort} className="bluicon ml-2" />}</th> */}
                  <th
                    onClick={() => sortScenari('projectOwner')}
                    className='d-none d-lg-table-cell text-center'>
                    <Trans code='A0081' />
                    {ordering.key === 'projectOwner' ? (
                      ordering.direction === 'ASC' ? (
                        <FontAwesomeIcon icon={faSortUp} className='bluicon ml-2' />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className='bluicon ml-2' />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className='bluicon ml-2' />
                    )}
                  </th>
                  <th
                    onClick={() => sortScenari('company')}
                    className='d-none d-md-table-cell text-center'>
                    <Trans code='A0059' />
                    {ordering.key === 'company' ? (
                      ordering.direction === 'ASC' ? (
                        <FontAwesomeIcon icon={faSortUp} className='bluicon ml-2' />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className='bluicon ml-2' />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className='bluicon ml-2' />
                    )}
                  </th>
                  {/* <th className="d-none d-lg-table-cell">Run</th> */}
                  <th
                    onClick={() => sortScenari('dataModifica')}
                    className='d-md-table-cell text-center'>
                    <span className=''>
                      <Trans code='A0071' />
                      {ordering.key === 'dataModifica' ? (
                        ordering.direction === 'ASC' ? (
                          <FontAwesomeIcon icon={faSortUp} className='bluicon ml-2' />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} className='bluicon ml-2' />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className='bluicon ml-2' />
                      )}
                    </span>
                    <span className='d-table-cell d-md-none'>
                      <Trans code='A0059' />{' '}
                    </span>
                  </th>
                  {/* <th className="d-none d-lg-table-cell"><Trans code="A0075"/></th>*/}
                </tr>
              </thead>
              <tbody>
                {scenari
                  // .filter(s => {
                  //   if(search.length > 0) {
                  //     return (s.scenario_name || '').toLowerCase().search(search.toLowerCase()) > -1 || (s.cod_nace_description || '').toLowerCase().search(search.toLowerCase()) > -1
                  //   }
                  //   return true
                  // })
                  .slice(page * recordsPerPage - recordsPerPage, page * recordsPerPage)
                  .map((scenario) => {
                    // console.log(current_username, scenario.projectOwner, user, scenario)
                    // user ? console.log("scenario.idUserOwner, user.idUse", scenario.idUserOwner, user.idUser) : console.log("scenario.idUserOwner, user.idUse", scenario.idUserOwner, null)
                    const showButtons =
                      (user && scenario.idUserOwner === user.idUser) || (user && user.role === '1');
                    const showDuplicateButton =
                      (user && scenario.idUserOwner === user.idUser) ||
                      (user && user.role === '1') ||
                      (user && scenario.company === user.organization);
                    const editTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
                    const excelTooltip = (props) => <Tooltip {...props}>Download Excel</Tooltip>;
                    const deleteTooltip = (props) => <Tooltip {...props}>Delete</Tooltip>;
                    const cloneTooltip = (props) => <Tooltip {...props}>Clone</Tooltip>;
                    const singleTooltip = (props) => <Tooltip {...props}>Single</Tooltip>;
                    const investimentoTooltip = (props) => (
                      <Tooltip {...props}>Investimento</Tooltip>
                    );

                    return (
                      <tr key={scenario.idScenario}>
                        <td>
                          {scenario.verificato === '1' ? (
                            <img src='img/logosmall.png' alt='Openeconomics' width='20' />
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          {parseInt(scenario.publish) === 0 ? (
                            scenario.scenario_name
                          ) : (
                            <Link
                              className='scenario-link'
                              to={{
                                pathname: `/scenari/${scenario.idScenario}/dashboard`,
                                state: { scenario },
                              }}>
                              {scenario.scenario_name}
                            </Link>
                          )}
                        </td>
                        <td className='d-none d-lg-table-cell text-center'>
                          {scenario.type === '0' ? (
                            <OverlayTrigger placement='top' overlay={singleTooltip}>
                              <FontAwesomeIcon icon={faStop} className='bluicon mr-3' />
                            </OverlayTrigger>
                          ) : (
                            <FontAwesomeIcon
                              icon={faThLarge}
                              title={propTrans({ prop: 'OEC0049' })}
                            />
                          )}
                          {scenario.scenario_type === '2' ? (
                            <FontAwesomeIcon
                              icon={faFileAlt}
                              title={propTrans({ prop: 'OEC0046' })}
                              className='bluicon'
                            />
                          ) : (
                            <OverlayTrigger placement='top' overlay={investimentoTooltip}>
                              <FontAwesomeIcon icon={faDollarSign} className='bluicon' />
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className='d-none d-md-table-cell text-center'>{scenario.status}</td>
                        {/* <td className="d-none d-md-table-cell">{scenario.cod_nace_description}</td> */}
                        <td className='d-none d-lg-table-cell text-center'>
                          {scenario.projectOwner}
                        </td>
                        <td className='d-none d-md-table-cell text-center'>{scenario.company}</td>
                        {/* <td className="d-none d-lg-table-cell">{parseInt(scenario.publish) === 0 ? 'NO' : 'SI'}</td> */}
                        <td className='text-center'>
                          {date(scenario.dataModifica || scenario.dataCreazione)}
                        </td>
                        <td className='d-none d-lg-table-cell text-center'>
                          {parseInt(scenario.vettorespesa) === 1 ? (
                            <>
                              <OverlayTrigger placement='top' overlay={editTooltip}>
                                <Button
                                  variant='link'
                                  disabled={!showButtons}
                                  href={'scenariosetup/' + scenario.idScenario}>
                                  <IcoEdit className='second-color ico-small edit' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={deleteTooltip}>
                                <Button
                                  variant='link'
                                  disabled={!showButtons}
                                  onClick={() => deleteScenario(scenario.idScenario)}>
                                  <IcoCanc className='second-color ico-small cancel' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={cloneTooltip}>
                                <Button
                                  variant='link'
                                  disabled={!showDuplicateButton}
                                  onClick={() => duplicaScenario(scenario.idScenario)}>
                                  <FontAwesomeIcon icon={faCopy} className='bluicon ml-2 mr-2' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={excelTooltip}>
                                <Button
                                  variant='link'
                                  disabled={!showDuplicateButton}
                                  href={
                                    getBaseUrl(true) +
                                    '/v1/progetto/' +
                                    scenario.idScenario +
                                    '/export'
                                  }>
                                  <FontAwesomeIcon icon={faFileDownload} className='bluicon ml-2' />
                                </Button>
                              </OverlayTrigger>
                            </>
                          ) : (
                            /* : <><div style={{width:110+'px'}}>&nbsp;</div></> */
                            <>
                              <OverlayTrigger placement='top' overlay={editTooltip}>
                                <Button
                                  variant='link'
                                  disabled
                                  href={'scenariosetup/' + scenario.idScenario}>
                                  <IcoEdit className='second-color ico-small edit' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={deleteTooltip}>
                                <Button
                                  variant='link'
                                  disabled={!showButtons}
                                  onClick={() => deleteScenario(scenario.idScenario)}>
                                  <IcoCanc className='second-color ico-small cancel' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={cloneTooltip}>
                                <Button
                                  variant='link'
                                  disabled
                                  onClick={() => duplicaScenario(scenario.idScenario)}>
                                  <FontAwesomeIcon icon={faCopy} className='bluicon ml-2 mr-2' />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement='top' overlay={excelTooltip}>
                                <Button variant='link' disabled href='#'>
                                  <FontAwesomeIcon icon={faFileDownload} className='bluicon ml-2' />
                                </Button>
                              </OverlayTrigger>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <Pagination className='mb-0'>{getPaginationItems(page, pages, 10)}</Pagination>
          <div>
            Scenari per pagina:&nbsp;
            <select
              onChange={(e) => {
                console.log(e.target.value);
                setRecordsPerPage(+e.target.value);
                //   setFinalQuery({ ...finalQuery, per_page: e.target.value });
              }}
              id='page-size'>
              {numberPagination}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScenariTab;
