import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Trans } from '@lib/i18n/index';
import { IcoCanc, IcoEdit } from '@icons';
import { putEditOrganization, deleteOrganization } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import ImageBase64Converter from '../imageBase64Converter';

const transformValues = (object, property) => {
  return Object.values(object[property]).length > 0
    ? object[property].map((data) => data.value)
    : [];
};

// const transformClassValues = (object, property) => {
//   return Object.values(object[property]).length > 0
//     ? object[property].map((data) => data.label)
//     : [];
// };

const ActionBtnRenderer = ({
  data,
  setRefresh,
  optionsType,
  // optionsClasses,
  optionsSam,
  // optionsDatasets,
}) => {
  const id = data.idOrganization;
  const [organization, setOrganization] = useState({});
  const [selectedType, setSelectedType] = useState([]);
  // const [selectedClass, setSelectedClass] = useState([]);
  // const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [selectedSam, setSelectedSam] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const animatedComponents = makeAnimated();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  console.log('selected organization', organization);
  // console.log('optionsClasses', optionsClasses);

  useEffect(() => {
    setOrganization(data);

    setSelectedType(
      optionsType
        .filter((optionType) => optionType.value === data.type)
        .map((filteredType) => ({
          value: filteredType.value,
          label: filteredType.label,
        }))
    );

    // setSelectedClass(
    //   optionsClasses
    //     .filter((optionClass) => data.classes.some((el) => optionClass.label === el.name))
    //     .map((filteredClass) => ({
    //       value: filteredClass.label,
    //       label: filteredClass.label,
    //     }))
    // );
    // setSelectedDatasets(
    //   optionsDatasets
    //     .filter((optionDataset) =>
    //       data.datasets.some((el) => Number(optionDataset.value) === Number(el.id))
    //     )
    //     .map((filteredDataset) => ({
    //       value: filteredDataset.value,
    //       label: filteredDataset.label,
    //     }))
    // );

    setSelectedSam(
      optionsSam.filter((optionSam) => {
        return data.sam.some((sam) => {
          return optionSam.value === sam.id;
        });
      })
    );
  }, [data, optionsType, optionsSam]);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  //   const onChange = (e, fieldname, setSelect) => {
  //     console.log('organization onChange', organization);
  //     //console.log('e test:', e)

  //     if (setSelect) {
  //       setSelect(e ?? []);

  //       setOrganization(() => ({
  //         ...organization,
  //         [fieldname]: e ? e.value ?? e : [],
  //       }));
  //     } else {
  //       setOrganization(() => ({
  //         ...organization,
  //         [e.target.name]: e.target.value,
  //       }));
  //     }
  //   };

  const onChange = (e, fieldname, setSelect) => {
    //console.log('onChange e', e);
    if (fieldname === 'immagine') {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Image = reader.result;

        setOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: base64Image,
        }));
      };

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      if (setSelect) {
        setSelect(e ?? []);

        setOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: e ? e.value ?? e : [],
        }));
      } else {
        setOrganization((prevOrganization) => ({
          ...prevOrganization,
          [e.target.name]: e.target.value,
        }));
      }
    }
  };

  const handleEditStatus = async (e) => {
    e?.preventDefault();

    setEmailIsInvalid(false);
    setSbShow(<></>);

    const {
      indirizzo,
      nomeResponsabile,
      cognomeResponsabile,
      emailResponsabile,
      sam,
      // classes,
      // datasets,
      ...restOrganization
    } = organization;

    const isOrganizationFilled = Object.values(restOrganization).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isOrganizationFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }

    if (emailResponsabile && !emailResponsabile.match(emailRegex)) {
      setEmailIsInvalid(true);
      handleStatusBar('error', <Trans code='A0739' />, false);
      return;
    }

    organization.sam = selectedSam;
    organization.sam = transformValues(organization, 'sam');

    // organization.classes = selectedClass;
    // organization.classes = transformClassValues(organization, 'classes');

    // organization.datasets = selectedDatasets;
    // organization.datasets = transformValues(organization, 'datasets');

    const editResult = await putEditOrganization(organization, id); //temp finche non accetta classes
    console.log('editResult', editResult);

    if (editResult === true) {
      handleStatusBar('success', <Trans code='A0524' />, true);

      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    } else if (editResult.message || editResult.error) {
      handleStatusBar('error', <Trans code='A0521' />, false);
    }
  };

  const handleDelete = async () => {
    //console.log('userID', userID);

    const deleteResult = await deleteOrganization(id);
    console.log('deleteResult', deleteResult);

    if (deleteResult === true) {
      handleStatusBar('success', <Trans code='A0749' />, true);

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', <Trans code='A0750' />, false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );
  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button variant='link' className='mr-0' onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      <Modal
        size='lg'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>
            <Trans code='A0751' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditStatus} id='form-admin-organization'>
            <Row className='mb-3'>
              <Form.Group as={Col} className='col-6 px-4' controlId='immagine'>
                <Form.Label className='mb-3'>
                  Logo&nbsp;<span className='required-field'>*</span>&nbsp;
                </Form.Label>
                <Form.Control
                  type='file'
                  name='immagine'
                  // defaultValue={organization.immagine}
                  onChange={(e) => onChange(e, 'immagine')}
                />
              </Form.Group>

              {organization?.immagine?.includes('data:image') && (
                <ImageBase64Converter
                  base64Image={organization.immagine}
                  className={'organization-logo'}
                />
              )}
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='organization'>
                <Form.Label className='mb-3'>
                  <Trans code='A0744' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='organization'
                  defaultValue={organization.organization}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='indirizzo'>
                <Form.Label className='mb-3'>
                  {' '}
                  <Trans code='A0745' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='indirizzo'
                  defaultValue={organization.indirizzo}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='nomeResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0746' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='nomeResponsabile'
                  defaultValue={organization.nomeResponsabile}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='cognomeResponsabile'>
                <Form.Label className='mb-3'>
                  {' '}
                  <Trans code='A0747' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='cognomeResponsabile'
                  defaultValue={organization.cognomeResponsabile}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='emailResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0748' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='emailResponsabile'
                  defaultValue={organization.emailResponsabile}
                  onChange={(e) => onChange(e)}
                  isInvalid={emailIsInvalid}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='type'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0639' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='type'
                  value={selectedType}
                  options={optionsType}
                  onChange={(e) => onChange(e, 'type', setSelectedType)}
                />
              </Form.Group>
            </Row>

            {/* <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  {' '}
                  <Trans code='A0376' />
                </Form.Label>
                <Select
                  id='multiselect-class'
                  value={selectedClass}
                  closeMenuOnSelect={false}
                  options={optionsClasses}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'classes', setSelectedClass)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  {' '}
                  <Trans code='A0483' />
                </Form.Label>
                <Select
                  id='multiselect-class'
                  value={selectedDatasets}
                  closeMenuOnSelect={false}
                  options={optionsDatasets}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'datasets', setSelectedDatasets)}
                />
              </Form.Group>
            </Row> */}

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='sam'>
                <Form.Label className='mb-3 mr-4'>
                  {' '}
                  <Trans code='SAM' />
                </Form.Label>
                <Select
                  id='multiselect-sam'
                  value={selectedSam}
                  closeMenuOnSelect={false}
                  options={optionsSam} //modificare con sam
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'sam', setSelectedSam)}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <Row>
            <Col className='col-4 px-3'>
              <span className='px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col className='px-3'>
            <span>
              <Trans code='A0572' />
            </span>
          </Col>
          <Col className='px-3 text-right'>
            <Button variant='danger' onClick={() => handleCloseModal(setShowModalEdit)}>
              <Trans code='F0057' />
            </Button>
            <Button variant='primary' onClick={(e) => handleEditStatus(e)} className='ml-3'>
              <Trans code='A0561' />
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0577' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRenderer;
