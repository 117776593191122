import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Trans } from '@lib/i18n';
import { getAuthUser, getScenarioMeta } from '@lib/api';
import { Button, Accordion, Card } from 'react-bootstrap';
import { ArrowDownUp, IcoBalance, IcoESG } from '@icons';
import {
  Ico89,
  Ico91,
  Ico33,
  Ico42,
  Ico47,
  Ico100,
  Ico101,
  Ico102,
  Ico103,
  Ico19,
} from '@icons/new';

const ImpactAnalysis = () => {
  const location = useLocation();
  const { pathname } = location;
  const { idScenario = null } = useParams();
  const [subactiveId, subSetActiveId] = useState('ia-0');
  const [haveExpenseVector, setHaveExpenseVector] = useState(0);
  const [user, setUser] = useState(null);
  const [userOwner, setUserOwner] = useState(null);
  const { state = null } = location;
  const [classNameSetup, setClassNameSetup] = useState('');

  const [navItems, setNavItems] = useState([]);

  const pathSegments = location.pathname.split('/');
  const isScenariV2 =
    pathSegments.length > 1 &&
    (pathSegments[1] === 'scenario' || pathSegments[1] === 'scenariowizard');

  // Scenari V2
  const navItemsData = [
    {
      to: `/scenario/${idScenario}/dashboard`,
      icon: <Ico100 className='submenu-ico' />,
      labelTextCode: 'F0060',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/impactanalysis`,
      icon: <Ico33 className='submenu-ico' />,
      labelTextCode: 'A0732',
      disabled: true,
      subRoutes: ['investment', 'gdp', 'jobs', 'incomes', 'taxes'],
    },
    {
      to: `/scenario/${idScenario}/dashboard/sroianalyses`,
      icon: <Ico101 className='submenu-ico' />,
      labelTextCode: 'SROI',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/taxonomy`,
      icon: <Ico102 className='submenu-ico' />,
      labelTextCode: 'A0759',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/esgrating`,
      icon: <Ico103 className='submenu-ico' />,
      labelTextCode: 'A0760',
      disabled: true,
    },
  ];

  // useEffect(() => {
  //   if (idScenario && isScenariV2 === true) {
  //     const composeNavItems = (scenario) => {
  //       setNavItems(navItemsData);
  //       getOEScenario(scenario).then((retrievedScenario) => {
  //         const analyses = retrievedScenario?.data?.analyses;

  //         const updatedNavItems = navItemsData.map((item) => {
  //           let isDisabled = true;
  //           if (item.labelTextCode === 'A0732') {
  //             // Impact Analysis
  //             isDisabled = !analyses?.some(
  //               // (el) => el.type === 'IA' && (el.status === 'Executed' || el.status === 'Draft')
  //               (el) => el.type === 'IA' && el.status === 'Executed'
  //             );
  //           } else if (item.labelTextCode === 'SROI') {
  //             // SROI Analyses
  //             isDisabled = !analyses?.some(
  //               // (el) => el.type === 'SROI' && (el.status === 'Executed' || el.status === 'Draft')
  //               (el) => el.type === 'SROI' && el.status === 'Executed'
  //             );
  //           } else if (item.labelTextCode === 'F0060') {
  //             // Summary
  //             isDisabled = analyses?.some(
  //               (el) => (el.type === 'IA' || el.type === 'SROI') && el.status !== 'Executed'
  //             );
  //           }

  //           return {
  //             ...item,
  //             disabled: isDisabled,
  //           };
  //         });
  //         setNavItems(updatedNavItems);
  //       });
  //     };
  //     composeNavItems(idScenario);
  //   } else {
  //     setNavItems([]);
  //   }
  // }, [idScenario]);

  useEffect(() => {
    if (state?.scenario?.idUserOwner) setUserOwner(+state?.scenario?.idUserOwner);
    else if (state?.scenario?.userIdNumber) setUserOwner(state?.scenario?.userIdNumber);
    else return;
  }, [state]);

  const isUserOwner = userOwner && +userOwner !== +user?.idUser && user?.role !== '1';

  const isUserIdDifferent = userOwner && +userOwner !== +user?.idUser && user?.role !== '1';

  const isDisabledLink = isUserOwner || isUserIdDifferent;

  const isDashboardScenario = pathname.includes(`/scenari/${idScenario}/dashboard`);
  const isDashboardScenarioWizard = pathname.includes(`/scenario/${idScenario}/dashboard`);

  const linkPath = isDashboardScenario
    ? { pathname: `/scenariosetup/${idScenario}`, state: { state } }
    : isDashboardScenarioWizard
    ? { pathname: `/scenariowizard/${idScenario}`, state: { state } }
    : '#';
  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const authResult = await getAuthUser();
      if (abortController.signal.aborted === false) {
        setUser(authResult.user);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (idScenario) {
      getScenarioMeta(idScenario).then((retrievedScenarioMeta) => {
        setHaveExpenseVector(Number(retrievedScenarioMeta?.vettorespesa));
      });
    }
  }, [idScenario, pathname]);

  useEffect(() => {
    if (idScenario) {
      let classname = 'menu-link';

      if (
        pathname.includes(`/scenariosetup/${idScenario}`) ||
        pathname.includes(`/scenariowizard/${idScenario}`)
      ) {
        //NEL WIZARD/SETUP
        classname = classname + ' active';
      } else if (pathname.includes(`/scenari/${idScenario}/dashboard`)) {
        //NELLA DASH VECCHIA
        if (!haveExpenseVector) {
          classname = classname + ' disabled-link';
        }
      }

      setClassNameSetup(classname);
    }
  }, [haveExpenseVector, idScenario, pathname]);

  const subToggleActive = (id) => {
    if (subactiveId === id) {
      subSetActiveId(null);
    } else {
      subSetActiveId(id);
    }
  };

  return (
    <ul className='menu-container'>
      <li className='menu-item'>
        {user &&
          ((parseInt(user.role) <= 3 &&
            (parseInt(user.idOrganization) === 1 || parseInt(user.idOrganization) === 14)) ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari'
              className={pathname.match(/.+scenari\/?$/gm) ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <Ico89 />
              </div>
              <div className='nav-text'>
                <Trans code='A0468' />
              </div>
            </Link>
          )}

        {/* {user &&
          (parseInt(user.idOrganization) === 1 ||
            parseInt(user.idOrganization) !== 14 ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari/newscenariosimpact'
              className={
                pathname.match(/.+newscenariosimpact\/?$/gm) ? 'menu-link active' : 'menu-link'
              }>
              <div className='nav-ico'>
                <Ico89 />
              </div>
              <div className='nav-text'>
                <Trans code='A0724' />
              </div>
            </Link>
          )} */}
        {user &&
          ((parseInt(user.role) <= 3 &&
            (parseInt(user.idOrganization) === 1 || parseInt(user.idOrganization) === 14)) ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari/comparators'
              className={pathname.match(/.+comparators\/?$/gm) ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <Ico91 />
              </div>
              <div className='nav-text'>
                <Trans code='A0222' />
              </div>
            </Link>
          )}
      </li>
      {idScenario && (
        <li
          className='divider'
          style={{
            margin: '10px 0 10px 0',
            position: 'relative',
            height: '1px',
            background: 'transparent',
          }}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              borderTop: '1px solid #D9D9D9',
            }}></div>
        </li>
      )}

      <li className='menu-item'>
        <Accordion className='sub-accordion' defaultActiveKey='ia-1'>
          {idScenario && (
            <Card className={subactiveId === 'ia-1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant='link'
                  eventKey='ia-1'
                  onClick={() => subToggleActive('ia-1')}
                  className='panel-toggle'
                  block>
                  <div className='nav-ico'>
                    <Ico42 />
                  </div>
                  <div className='nav-text'>
                    <Trans code='C00498' />
                  </div>
                  <div className='arrow-ico'>
                    <ArrowDownUp />
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='ia-1'>
                <Card.Body>
                  <ul className='menu-container'>
                    {false && !window.location.href.includes('externalytics.com') && (
                      <li className='menu-item'>
                        <Link
                          to='#'
                          //to={{pathname: `/scenari/${idScenario}/cba`, state: { state }}}
                          className={
                            pathname.match(/.+cba\/?$/gm) ? 'menu-link active' : 'menu-link'
                          }>
                          <div className='nav-ico'>
                            <IcoBalance style={{ width: '25px' }} />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0467' />
                          </div>
                        </Link>
                      </li>
                    )}
                    {idScenario && user && parseInt(user.role) <= 3 && (
                      <li className='menu-item'>
                        <Link
                          to={linkPath}
                          className={`${classNameSetup} ${isDisabledLink ? 'disabled-link' : ''}`}>
                          <div className='nav-ico'>
                            <Ico19 />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0474' />
                          </div>
                        </Link>
                      </li>
                    )}
                    {navItems &&
                      navItems.map((item, index) => (
                        <li className='menu-item' key={index}>
                          <Link
                            to={item.disabled ? '#' : item.to}
                            className={
                              (
                                item.subRoutes
                                  ? pathname === item.to ||
                                    item.subRoutes.some((el) => pathname.includes(el))
                                  : pathname === item.to
                              )
                                ? 'menu-link active'
                                : 'menu-link' + (item.disabled ? ' disabled-link' : '')
                            }>
                            <div className='nav-ico'>{item.icon}</div>
                            <div className='nav-text'>
                              <Trans code={item.labelTextCode} />
                            </div>
                          </Link>
                        </li>
                      ))}
                    {false && !window.location.href.includes('externalytics.com') && (
                      <li className='menu-item'>
                        <Link
                          to='/scenari'
                          className={
                            pathname.match(/.+esg\/?$/gm)
                              ? 'menu-link active disabled-link'
                              : 'menu-link disabled-link'
                          }>
                          <div className='nav-ico'>
                            <IcoESG className='disabled-svg' />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0469' />
                          </div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </li>
    </ul>
  );
};
export default ImpactAnalysis;
