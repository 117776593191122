import React from 'react';
import { ScenariTab } from '@components/scenari';

const Scenari = () => {
  return (
    <div id='scenari' className='megaContent'>
      <div className='container-fluid'>
        <ScenariTab />
      </div>
    </div>
  );
};

export default Scenari;
