import React, { useState, useEffect, useContext } from 'react';
import { getAuthUser, getOrganizations } from '@lib/api';
import ImageBase64Converter from './adminManagement/imageBase64Converter';
import { LogoContext } from './LogoContext';
const UserLogo = ({ className }) => {
  const { logo, setLogo } = useContext(LogoContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const authResult = await getAuthUser();
      if (abortController.signal.aborted === false) {
        setUser(authResult.user);
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const orgResults = await getOrganizations();
      if (abortController.signal.aborted === false) {
        setLogo(
          orgResults
            .filter((org) => org.idOrganization === user.idOrganization)
            .map((filteredOrg) => {
              return filteredOrg.immagine;
            })
            .join()
        );
      }
    };
    if (user && user.idOrganization) {
      fetchData();
    }
    return () => {
      abortController.abort();
    };
  }, [user, setLogo]);
  // console.log('IMAGo', imageOrganization);
  if (!user) return null;
  //   return <img className={className} src={'map'} alt='user logo' />;
  return <ImageBase64Converter className={className} base64Image={logo} />;
};

export default UserLogo;
