import React, { useState, useEffect } from "react";
import { useParams, Redirect } from 'react-router-dom'
import { getScenario, getScenarioMeta } from '@lib/api'
import Submenu from '@components/scenario/Submenu'
import Dashboard from './components/dashboard'
import Investment from './components/investment'
import GDP from './components/gdp'
import Production from './components/production'
import Taxes from './components/taxes'
import Incomes from './components/incomes'
import Jobs from './components/jobs'
import { SDG } from '@pages';
import i18n from '@lib/i18n'
import Currency from '@lib/currency'
import ScenarioHeader from '@components/scenario/ScenarioHeader'

const Scenario = () => {
  const { idScenario = null } = useParams()
  const [scenario, setScenarioMeta] = useState(null)
  const [additionalInfo, setAdditionalInfo] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [investment, setInvestment] = useState(null)
  const [gdp, setGdp] = useState(null)
  const [production, setProduction] = useState(null)
  const [taxes, setTaxes] = useState(null)
  const [incomes, setIncomes] = useState(null)
  const [jobs, setJobs] = useState(null)
  const [general, setGeneral] = useState(null)
  const [activeTab, setActiveTab] = useState('general')
  //console.log('scenario:', scenario);

  const setScenario = () => {
    getScenario(idScenario)
      .then((retrievedScenario) => {
        setAdditionalInfo(retrievedScenario.additionalInfo)
        setDashboard(retrievedScenario.dashboard)
        setInvestment(retrievedScenario.investment)
        setGdp(retrievedScenario.pil)
        setProduction(retrievedScenario.production)
        setTaxes(retrievedScenario.taxes)
        setIncomes(retrievedScenario.incomes)
        setJobs(retrievedScenario.jobs)
        setGeneral(retrievedScenario.general)
      })

    getScenarioMeta(idScenario)
      .then((retrievedScenarioMeta) => {
         setScenarioMeta(retrievedScenarioMeta);
   });
  }

  useEffect(() => {
    if(idScenario) {
      setScenario()
      i18n.on('languageChanged', setScenario)
      Currency.on('currencyChanged', setScenario)
      return function clenup() {
        i18n.off('languageChanged', setScenario)
        Currency.off('currencyChanged', setScenario)
      }
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!idScenario) return <Redirect to='/scenari' />

  if(!scenario || !dashboard) return null
  
  return (
    
    <div id="dashboard" className="megaContent">
      <div className="container-fluid">
        <ScenarioHeader scenario={scenario} dataset={general?.title} />
        <div className="row">
          <div className="col">
            {additionalInfo.purpose ?? dashboard.analysisScopeOfWork}
          </div>
        </div>
        <Submenu callback={setActiveTab} active={activeTab}/>
        {activeTab === 'general' && <Dashboard dashboard={dashboard} additionalInfo={additionalInfo} navigateCallback={setActiveTab} />}
        {activeTab === 'investment' && <Investment investment={investment} scenario={scenario}/>}
        {activeTab === 'gdp' && <GDP gdp={gdp} />}
        {activeTab === 'production' && <Production production={production} />}
        {activeTab === 'taxes' && <Taxes taxes={taxes} />}
        {activeTab === 'incomes' && <Incomes incomes={incomes} />}
        {activeTab === 'jobs' && <Jobs jobs={jobs} />}
        {activeTab === 'sdg' && <SDG />}
      </div>
    </div>
  );
}

export default Scenario;
